import { BASE_URL } from "../constants";
import { POST, GET, AUTH_GET } from "../index";

export const getAdminUser = async (data, token) => {
  //////////////console.log('what am i sendinggggggggggg', data);
  const ENDPOINT = "/api/admin/users";
  return handleGET(ENDPOINT, data, token);
};

export const getRegistrationData = async (data, token) => {
  //////////////console.log('send registrationnnnnnnnnnnnnnnnnnnnn dta', data);
  const ENDPOINT = "/api/admin/registration_chart";
  return handleGET(ENDPOINT, data, token);
};

export const getEditUser = async (data, token) => {
  const ENDPOINT = "/api/admin/misc-actions";
  return handlePOST(ENDPOINT, data, token);
};

export const updateEditUser = async (data, token) => {
  const ENDPOINT = "/api/admin/misc-actions";
  return handlePOST(ENDPOINT, data, token);
};

export const registerAdminUser = async (data, token) => {
  const ENDPOINT = "/api/admin/register-admin-user";
  return handlePOST(ENDPOINT, data, token);
};

// Generic handler for POST requests
const handlePOST = async (endpoint, data, token) => {
  try {
    const response = await POST(BASE_URL, endpoint, data, token);
    return response.data.results.response; // Access 'response' key here
  } catch (error) {
    let errorMessage = "Something went wrong.";
    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};

// Generic handler for GET requests
const handleGET = async (endpoint, data, token) => {
  try {
    const response = await AUTH_GET(BASE_URL, endpoint, data, token);
    //////////////console.log('Receivedddddddddddddddddddddddddd', response.data.results.response);
    return response.data.results.response;
  } catch (error) {
    //////////////console.log('errrrrrrrrrrrrrrrrrr', error.message);
    let errorMessage = "Something went wrong.";

    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};
